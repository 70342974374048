html {
  background-color: rgba(0, 0, 0, 1);
}

.homepage {
  background-color: rgba(0, 0, 0, 1);
  width: 100vw;
  /* height: 800vw; */
  min-height: 100vh;
}

.background {
  width: 100vw;
  /* height: 70vw; */
  min-height: 100vh;
  position: absolute;
  background-position-x: center;
  background-image: url(/public/images/background.webp);
  background-repeat: no-repeat;
  background-size: cover;
}

.main-container {
  width: 100vw;
  min-height: 100vh;
  margin: 0 auto;
  position: relative;
  background-color: rgba(0, 0, 0, 0);
}

.navbar {
  width: 156px;
  height: 28px;
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: rgba(0, 0, 0, 1);
  border-radius: 4px;
  padding: 4px;
}

.navbar a {
  width: 52px;
  height: 28px;
  text-align: center;
  display: inline-flex;
  align-items: center;
  color: rgba(255, 255, 255, 1);
  border-radius: 4px;
}

.navbar a .fontawesome {
  margin: 0 auto;
  display: block;
}

.navbar a:hover {
  background-color: rgba(255, 56, 56, 1);
}

.title-container {
  position: absolute;
  width: 100%;
  min-height: 20vw;
  margin-top: 35vw;
  background-color: rgba(0, 0, 0, 0);
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.logo {
  width: 16vw;
  height: 16vw;
  background: url(/public/images/logo.svg) no-repeat;
  background-size: contain;
  background-position: center;
}

.title {
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 9.25vw;
  font-family: 'Audiowide', cursive;
  text-align: left;
  line-height: 0.85;
  white-space: nowrap;
  margin-left: 4vw;
}

.status-container {
  position: absolute;
  width: 100%;
  min-height: 20vw;
  margin-top: 64vw;
  background-color: rgba(0, 0, 0, 0);
  display: flex;
}

.status {
  overflow-wrap: break-word;
  margin: auto;
  color: rgba(255, 255, 255, 1);
  font-size: 2.2vw;
  font-family: 'Audiowide', cursive;
  text-align: center;
  white-space: nowrap;
}

@media screen and (min-width: 1080px) {
  .main-container {
    width: 1080px;
  }

  .title-container {
    margin-top: 380px;
  }

  .logo {
    width: 186px;
    height: 186px;
  }

  .title {
    font-size: 100px;
  }

  .status-container {
    margin-top: 750px;
  }
}

@media screen and (max-width: 750px) {
  .title-container {
    flex-direction: column;
  }
  
  .logo {
    margin: 0 auto;
  }

  .title {
    margin: 3vw auto;
    text-align: center;
  }

  .status-container {
    margin-top: 80vw;
  }
}